<div class="card-info">   
    <mat-label class="card-info-title">{{'Whiteboard.Title' | translate}}
        <span class="tooltip-row" (click)="closeWhiteboard()">
            <button mat-icon-button (click)="closeWhiteboard()">
                <span class="material-symbols-outlined icon-close-button">close</span>
            </button>
        </span>
    </mat-label>
    <div #whiteboardContainer class="whiteboardContainer" (resized)="onResized($event)" >
        <div class="whiteboardDiv" [style.width.px]="canvasContainerWidth" [style.height.px]="canvasContainerHeight">        
        <div [style.width.px]="options.canvasWidth" [style.height.px]="options.canvasHeight">
            <ng-whiteboard #whiteboardComponent
            [drawingEnabled]="drawingEnabled"
            [selectedTool]="toolSelected.value"
            [strokeColor]="strokeColor"
            [fill]="fillColor" 
            [fullScreen]="false"
            [canvasHeight]="options.canvasHeight"
            [canvasWidth]="options.canvasWidth"
            [zoom]="compositeZoom"
            (save)="onSave($event)"
            (dataChange)="onDataChange($event)"
            (selectElement)="onSelectElement($event)"
            (deleteElement)="onDeleteElement($event)"
            ></ng-whiteboard>
        </div>
    </div>
    </div>
    <div class="whiteboard-toolbar toolbar-background">     
        <div class="toolbar-filler">
            <div class="toolbar-selector">
                <mat-form-field class="toolbar-form" appearance="fill" subscriptSizing="dynamic">
                    <mat-select panelClass="panel-tool-select-option" [(value)]="toolSelected">	
                    <mat-select-trigger>
                        <span class="font-icon-button material-symbols-outlined tool-icon"
                        [matTooltip]="'Whiteboard.' + toolSelected.value | translate">
                            {{toolSelected.icon}}
                        </span>
                    </mat-select-trigger>
                    <mat-option class="item-option" *ngFor="let tool of tools" [value]="tool">
                        <mat-icon class="font-icon-button material-symbols-outlined tool-icon">{{tool.icon}}</mat-icon>{{'Whiteboard.' + tool.value | translate}}
                    </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <button mat-raised-button class="operator-button" aria-label="Palette Tool"
            [matTooltip]="'Whiteboard.ColorSelector' | translate"
            ngx-colors-trigger [(ngModel)]="strokeColor">
                <span class="font-icon-button material-symbols-outlined palette-button" [style.background]="strokeColor">palette</span>
            </button>
            <button mat-raised-button class="operator-button" aria-label="Undo" 
            [matTooltip]="'Whiteboard.Undo' | translate"
            (click)="undo()">
                <span class="font-icon-button material-symbols-outlined">undo</span>
            </button> 
            <button mat-raised-button class="operator-button" aria-label="Redo" 
            [matTooltip]="'Whiteboard.Redo' | translate"
            (click)="redo()">
                <span class="font-icon-button material-symbols-outlined">redo</span>
            </button> 	
        </div>           
        <button mat-raised-button class="operator-button" aria-label="Analize image" 
        [matTooltip]="'Whiteboard.Analize' | translate"
        (click)="analyzeImage($event)">
            <span class="font-icon-button material-symbols-outlined">wallpaper</span>
        </button>
        <button mat-raised-button class="operator-button" aria-label="Send and close" 
        [matTooltip]="'Video.whiteboardSend' | translate"
        (click)="sendVideoCaptureAndCloseWhiteboard()">
            <span class="font-icon-button material-symbols-outlined">share_windows</span>
        </button>  
    </div>
</div>
<div style="display: none;">
<pre #analyze style="font-size: 0.7em; text-align: left;">

</pre>
</div>