import { Injectable } from "@angular/core";
import { BaseHttp } from "./base.http";
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app.config.service';
import { Store } from '@ngrx/store';
import { Observable } from "rxjs";
import { ImageAnalysisResult } from "../models/WebServer/ImageAnalyzer/v4.0/ImageAnalysisResult";
import { ImageAnalysisInput } from "../models/WebServer/ImageAnalyzer/v4.0/imageAnalysisInput";

@Injectable()
export class ImageAnalyzer extends BaseHttp {
   
    constructor(http: HttpClient, config: AppConfigService, store: Store) {
        super(http, store, config);
    }

    public analyze(input: string) : Observable<ImageAnalysisResult> {

        const imageAnalysisInput: ImageAnalysisInput = { inputImg: input, VisualFeatures: null};

        return this.postRequest<ImageAnalysisResult>(`${this.config.apiBaseUrl}/imageAnalyzer/analyze`, imageAnalysisInput, true);
    }
}