import { Injectable } from "@angular/core";
import { SessionDescriptionHandler, SimpleUser, SimpleUserDelegate, SimpleUserMediaRemote, SimpleUserOptions, defaultSessionDescriptionHandlerFactory } from "src/libs/SipJS/platform/web";
import { AppConfigService } from "./app.config.service";
import { Invitation, InvitationAcceptOptions, InviterOptions, MessagerOptions, RegistererRegisterOptions, Session, SessionInviteOptions, URI, UserAgentOptions } from "src/libs/SipJS";
import { RootState } from "../store/root.state";
import { Store } from "@ngrx/store";
import * as appActions from "src/app/store/actions/app.actions";
import * as phoneActions from "src/app/store/actions/phone.actions";
import { MediaService } from "./media.service";
import { FELoggerService, LogSource } from "./feLogger.service";
import { PhoneStatusEnum } from "../models/phoneStatus";
import { I18Service } from "./i18.service";
import { NotificationService } from "./notification.service";
import { PartecipantRoles } from "../models/WebServer/partecipantRoles";
import { PartecipantStatus } from "../models/WebServer/partecipantStatus";

const MAX_ONREJECT_RETRY = 5;
@Injectable()
export class SipService {
  private sendingInvite = false;
  private inviteAttempts = 0;
  private ua: SimpleUser | undefined;
  private isRegistered = false;
  private stoppingAllSessions = false;

  constructor(
    private n: NotificationService,
    private feLogger: FELoggerService,
    private mediaService: MediaService,
    private config: AppConfigService,
    private store: Store<RootState>) {
    console.debug(`SipSevice constructor`);
  }
  init() {
    console.log(`sipService init`)
    const selfThis = this;

    if (this.ua) {
      FELoggerService.warn(`SipService already initialized - register again`);
      this.ua.register().then(() => this.store.dispatch(appActions.wssSipRegistered({ is: true })));
      return;
    }
    const rtrProxy = this.config.rtrProxy;
    if (!rtrProxy) {
      FELoggerService.error(`SipService rtrProxy not defined`);
      return;
    }
    const aor = this.createAor(this.config.partecipantId);
    this.store.dispatch(appActions.loadingMessage({ message: I18Service.get("Loading.Connecting") }));
    const userAgentOptions: UserAgentOptions = {
      // uri: UserAgent.makeURI(`sip:${this.uaConfig.uri}`),
      sessionDescriptionHandlerFactoryOptions: {
        iceGatheringTimeout: rtrProxy.iceGatheringTimeout,
        peerConnectionConfiguration: { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }, { urls: 'stun:108.177.126.127:19302' }] },
      },
      sessionDescriptionHandlerFactory: defaultSessionDescriptionHandlerFactory(this.mediaService.localMediaStreamFactory()),
      transportOptions: {
        server: `${rtrProxy.wssUrl}`,
        keepAliveInterval: rtrProxy.sipKeepAliveInterval,
        keepAliveDebounce: rtrProxy.sipKeepAliveInterval
      },
      logBuiltinEnabled: rtrProxy.traceSip,
      gracefulShutdown: true,
      logConfiguration: false,
      displayName: AppConfigService.role
      // userAgentString: `SipJs--${LIBRARY_VERSION}`
    };


    const options: SimpleUserOptions = {
      userAgentOptions,
      delegate: this.createUserAgentDelegate(),
      aor,
      media: {
        // local: function(): SimpleUserMediaLocal { //dave serve solo per fare il "play" del video locale quando la call è attivo: session-manager private setupLocalMedia()
        //   return {video: (document.getElementById('localVideo') as HTMLVideoElement)}
        // },
        remote: function (s: Session): SimpleUserMediaRemote {

          return { video: selfThis.mediaService.getRemoteVideoRender(s.remoteIdentity.uri.user )}// 
            //(document.getElementById('remoteVideo') as HTMLVideoElement) }
        }
      },
      registererOptions: {logConfiguration: false, expires: rtrProxy.registerExpires, refreshFrequency: 95, extraHeaders: this.getDefaultExtraHeaders()}
    };
    this.ua = new SimpleUser(rtrProxy.wssUrl, options);

    this.connect();

  }

  disconnecting=false
  disconnect() {
    if (!this.ua || this.disconnecting) return;
    console.trace(`sipService: disconnect`)
    
    this.disconnecting = true;
    this.ua?.disconnect()
      .catch(() => this.disconnecting = false)
      .finally(() => this.disconnecting = false);
   
  }

  // solo al "primo"
  sendMessage(body: string, to: string, first: boolean) {
    const rtrProxy = this.config.rtrProxy;
    const toAor = `sip:${to}@${rtrProxy.domain}`;
    const options: MessagerOptions = {
      extraHeaders: [...this.getDefaultExtraHeaders(), `SM-StoreMessage: ${first}`]
    }
    this.ua?.message(toAor, body, 'text/plain', options)

  }
  
  checkPartecipantToCall() {
    if (this.config.isReceiver && !this.config.isSuper) return;
    // if (!this.onCallPipe.transform( this.config.AS?.phoneStatus, true) && // non sono in call
    //   !this.config.operatorsStatus.find(z => this.onCallPipe.transform(z.phoneStatus, true)) //e non c'è operatore in call
    // ) {
    //   return;
    // }
    if (!this.config.iamOnCall) return;
    if (!this.isRegistered) return;
    if (this.stoppingAllSessions) return


    //operatori con cui non si è ancora in chiamata
    const operatorsToCall = this.config.operatorsStatus.filter(z => !this.ua?.onCallWith(z.partecipantId));
    const othersToCall = this.config.otherPartecipantsOnCall.filter(z => !this.ua?.onCallWith(z.partecipantId));
    const toCall = [...operatorsToCall, ...othersToCall]; 

    if(toCall.length){
      console.log(`sipService checkPartecipantToCall => ${toCall.length}`)
      this.callPartecipants(toCall, true);
    }
  }

  handleCalls() {
    if (this.stoppingAllSessions) return;
    //se sono un receiver.. enable/disable media
    if (this.config.isReceiver && !this.config.isSuper){
      this.enableMedia();
    }
    else {//startCalls for others
      const toCall = [...this.config.operatorsStatus, ...this.config.otherPartecipantsOnCall]; 
      console.log(`sipService handleCalls => ${toCall.length}`)
      this.callPartecipants(toCall);
    }
  }

  private partecipantsToCall:PartecipantStatus[] = [];
  private callingPartecipants = false;
  private lastCallingPartecipants: number = 0;
  private readonly CALL_INTERVAL = 1000;
  private callPartecipants(partecipants: PartecipantStatus[], automatic = false) {
 
    if(partecipants.length == 0 && this.partecipantsToCall.length == 0) {
      this.callingPartecipants = false;
      return;
    }
    const now = Date.now();
    if(this.callingPartecipants && (now-this.lastCallingPartecipants) < 17 * 1000 && automatic) {
      console.log(`sipService - already callingPartecipants`)
      return;
    }
    this.callingPartecipants = true;
    this.lastCallingPartecipants = now;
    
    this.partecipantsToCall = [...this.partecipantsToCall];
    partecipants.forEach( p=> {
      if (!this.partecipantsToCall.find( z => z.partecipantId == p.partecipantId)){
        this.partecipantsToCall.push(p);
      }
    });


    //this.dequeuePartecipantsToCall();
    this.partecipantsToCall.forEach( (p, idx) => { //scodo tutti insieme

      setTimeout(()=>{
          this.dequeuePartecipantsToCall();
      }, this.CALL_INTERVAL*idx)
    })


    // const appState = this.config.AS!;
    // partecipants.forEach( (p, idx) => {
    //   setTimeout(()=>{
    //     this.startCall({ to: p.partecipantId, toType: p.partecipantRole, enableAudio: appState.microphoneEnabled, enableVideo: appState.videoEnabled})
    //   }, this.CALL_INTERVAL*idx)
    // });
    // const promises = partecipants.map( (p: PartecipantStatus) => { 
    //   return this.startCall({ to: p.partecipantId, toType: p.partecipantRole, enableAudio: appState.microphoneEnabled, enableVideo: appState.videoEnabled}).
    //     then(()=>{
    //       console.log(`sipService startedCall to [${ p.partecipantRole}] ${p.partecipantId}`);
    //   })
    // })

    // return Promise.all(promises).then();


  }
  private dequeuePartecipantsToCall(){
    if(this.partecipantsToCall.length == 0) {
      this.callingPartecipants = false;
      return;
    }
    const p = this.partecipantsToCall.shift();
    if(!p){
      if(this.partecipantsToCall.length > 0) {
        this.dequeuePartecipantsToCall();
        return;
      }
      this.callingPartecipants = false;
      return;
    }
    const appState = this.config.AS!;
    this.startCall({ to: p.partecipantId, toType: p.partecipantRole, enableAudio: appState.microphoneEnabled, enableVideo: appState.videoEnabled})
  }

  private startCall(opts: { to: string, toType: PartecipantRoles, enableVideo: boolean, enableAudio: boolean}): Promise<void> {
    if (this.ua?.onCallWith(opts.to)){//} && !opts.force) { //sessione esiste già, sistemiamo i flussi   //dave-mvideo!!!
      console.log(`sipService - enablingMedia`)
      return this.enableMedia()//{ audio: opts.enableAudio, video: opts.enableVideo });
      
    }
    
    if (this.config.isReceiver && !this.config.isSuper) return Promise.resolve(); // full receiver don't start calls!!
    console.log(`sipService startCall to [${opts.toType}] ${opts.to}`);

    const myoptions: SessionInviteOptions = {
      requestOptions: {
        extraHeaders: this.getDefaultExtraHeaders(),
      },
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: true
        }
      },
      
    };
    const options = { ...myoptions }; //, ...otherOptions };
    const inviterOptions: InviterOptions = {
      extraHeaders: this.getDefaultExtraHeaders(),
      params: {toDisplayName: opts.toType}
    };

    console.log(`sipService - callRequest STARTING to [${opts.toType}] ${opts.to}`)
    return this.ua?.call(this.createAor(opts.to), inviterOptions, myoptions) || Promise.resolve()

  }

  endAllCalls() { 
    if (!this.isInCall())  return;
    this.stoppingAllSessions = true; 
    console.log(`sipService - endAllCalls - this.stoppingAllSessions ${this.stoppingAllSessions}`);
    this.ua?.hangupAll();
  }
  
  endCall(user: string) {
    if (!this.isInCall())  return;
    this.ua?.hangup(user);
  }

  private enableMedia(): Promise<void> {//opts: MediaEnable) {
    if (!this.isInCall()) return Promise.resolve();

    if (!this.mediaService.isCurrentMediaChanged()){
      console.log(`sipService - already enableMedia . return`);
      return Promise.resolve();
    }


    console.log(`sipService - enableMedia`);
    const self = this;

    return this.mediaService.setupLocalMediaForCall()//opts)
      .then((stream: MediaStream) => {
        if (stream.getTracks().length) {
          self.replaceStream(stream);
        }
        self.store.dispatch(phoneActions.phoneChangeStatus({ status: PhoneStatusEnum.TALKING }));
      })
      .catch((e) => {
        FELoggerService.error(`sipService - enableMedia  ERROR`, LogSource.MediaAudio, e)
      });
  }

  public switchCamera(useSelectedId = false) {
    if (!this.isInCall()) return;
    console.log(`sipService - setupLocalMediaForCall - switchCamera`);
    const self = this;

    //stop current sending Streams (no open concurrent camera on android)
    this.stopSendingStreams()

    this.mediaService.switchCameraForCall(useSelectedId).then((stream: MediaStream) => {
      self.replaceStream(stream);
    })
    .catch((e) => {
      FELoggerService.error(`sipService - switchCamera ERROR`, LogSource.MediaAudio, e)
    });
  }
  public changeMediaDevice(){
    if (!this.isInCall()) return;
    console.log(`sipService - setupLocalMediaForCall - changeMediaDevice`);
    const self = this;

    //stop current sending Streams (no open concurrent camera on android)
    this.stopSendingStreams()

    this.mediaService.setupLocalMediaForCall(true, true).then((stream: MediaStream) => {
      self.replaceStream(stream);
    })
    .catch((e) => {
      FELoggerService.error(`sipService - switchCamera ERROR`, LogSource.MediaAudio, e)
    });
  }
  public videoEditStream(start: boolean) {
    if(start && this.mediaService.videoEditStream) {
      this.replaceStream(this.mediaService.videoEditStream);
    } else {
      this.enableMedia();
    }
  }
  
  private replaceStream(stream: MediaStream) {
    if (!this.isInCall()) return;
    const videoTrack = stream.getVideoTracks()[0];
    const audioTrack = stream.getAudioTracks()[0];
    this.ua?.sessions.forEach( session =>{
      // const sdh = this.ua?.currentSession?.sessionDescriptionHandler;
      const sdh = session.sessionDescriptionHandler;
      if (sdh && sdh instanceof SessionDescriptionHandler) {
        if (sdh.peerConnection) {
          sdh.peerConnection.getSenders().forEach(s => {
            if (s.track && s.track.kind == 'video' && videoTrack.kind == 'video' && s.track?.id != videoTrack.id) {
              console.log(`sipService - replacing video track ${s.track?.id} whit ${videoTrack.id}`);
              s.replaceTrack(videoTrack).then(() => {
                console.log(`sipService - video track replaced successfully on session id:${session.id}`, sdh.peerConnection?.getSenders());//, Framework.ReinviteDirection);
              });
            }
            if (s.track && s.track.kind == 'audio' && audioTrack.kind == 'audio' && s.track?.id != audioTrack.id) {
              console.log(`sipService - replacing audio track ${s.track?.id} whit ${audioTrack.id}`);
              s.replaceTrack(audioTrack).then(() => {
                console.log(`sipService - audio track replaced successfully on session id:${session.id}`, sdh.peerConnection?.getSenders());//, Framework.ReinviteDirection);
              });
            }
          });
        }
      }
    })
  }

  private stopSendingStreams() {
    if (!this.isInCall()) return;
    this.ua?.stopSendingStreams();
  }

  private createUserAgentDelegate(): SimpleUserDelegate {
    const selfThis = this;
    const delegate: SimpleUserDelegate = {
      onServerConnect: () => {
        console.log('sipService - onServerConnect')
        selfThis.store.dispatch(appActions.wssSipConnected());
      },

      onServerDisconnect: (error: Error) => {
        console.log(`sipService - onServerDisconnect ${error?.name} - ${error?.message}`)
        if (error && error.name == 'maxAttempts') {
          const msg =`${I18Service.get('Error.Signalr')}\n${I18Service.get('SA_UNAVAILABLEGLBODY')}` 
          selfThis.store.dispatch(appActions.showErrorPage({ message: msg }))
          return;
        }
        this.isRegistered = false;
        selfThis.store.dispatch(appActions.wssSipRegistered({ is: false }));
        
      },

      onCallReceived: async (invitation: Invitation) => {

        //blocca una eventuale seconda chiamata se già presente
        const from=invitation?.remoteIdentity?.uri?.user || '';
        const displayName= invitation?.remoteIdentity.displayName || 'none'
        const sessionCount=this.ua?.sessionCount(from) || 0;
        if( sessionCount> 1){
          console.log(`sipService - onCallReceived - refuse because already on call with ${from}`);  
          await this.ua?.decline(invitation);
          return;
        }

        console.log(`sipService - onCallReceived - Incoming Call from [${displayName}] ${from}!`);
        
        const opts: InvitationAcceptOptions = {
          extraHeaders: this.getDefaultExtraHeaders()
        }
        await this.ua?.answer(opts);
      },
      onCallCreated: (session: Session) => { // sia in che out
        this.logSessionEvent(session, 'onCallCreated to');
        selfThis.store.dispatch(phoneActions.phoneChangeStatus({ status: PhoneStatusEnum.CALLING }));
      },

      onCallAnswered: (session: Session) => { // sia in che out
        this.logSessionEvent(session, 'onCallAnswered to');
        selfThis.store.dispatch(phoneActions.phoneChangeStatus({ status: PhoneStatusEnum.TALKING }));
        this.dequeuePartecipantsToCall();
      },
  
      onCallHangup: (session: Session) => {
        this.logSessionEvent(session, `onCallHangup remaining sessions: ${this.ua?.sessions.length} to`);
        if (!this.ua?.sessions.length) {
          selfThis.store.dispatch(phoneActions.phoneChangeStatus({ status: PhoneStatusEnum.IDLE }));
          this.mediaService.stopLocalStreams();
          setTimeout(()=>{ // ritardo lo "sblocco"
            this.stoppingAllSessions = false;
            console.log(`sipService ********* SBLOCCO endAllSessions iamAnCall ${this.config.iamOnCall} -this.stoppingAllSessions:${this.stoppingAllSessions}`)

          }, 1500)
        } else {
          //proviamo a riattiare il remoteStream bloccato all'hangup
          // this.mediaService.playRemoteStream();
        }
        if(!this.stoppingAllSessions) {
          const timeout = this.config.isReceiver ? 1000 : 1500;
          setTimeout(()=>{ // ritardo lo "sblocco"
            this.mediaService.setZoomOnTalking();
          }, timeout)
        }
      },
      onMessageReceived: (from: URI, message: string) => {
        console.log('sipService - onMessageReceived');
        selfThis.store.dispatch(appActions.recvTextMessage({ body: message, from: from.user || from.toString(), rtpCallId: undefined }))
      },
      onRegistered: () => {
        console.log('sipService - onRegistered')
        if (selfThis.tryRegister > 1) {
          selfThis.store.dispatch(appActions.loadingMessage({message: ''}));
          selfThis.tryRegister = 1;
        }
        this.isRegistered = true;
        selfThis.store.dispatch(appActions.wssSipRegistered({ is: true }));
      },
      onUnregistered: () => {
        console.log('sipService - onUnregistered');
        this.isRegistered = false;
        selfThis.store.dispatch(appActions.wssSipRegistered({ is: false }));
      },
      onAttemptReconnection: (n,m) => {
        // NotificationService.show(I18Service.get('SNACKBAR.RECONNECTIONATTEMPT', {current: n, max: m}));
        const message = I18Service.get('SNACKBAR.RECONNECTIONATTEMPT', {current: n, max: m});
        FELoggerService.log(message, LogSource.PhoneEvents)
        selfThis.store.dispatch(appActions.loadingMessage({message}));
      }
    };
    return delegate;
  }

  private connect() {
    if (!this.ua) return;
    console.log(`sip wss connecting`)
    this.ua.connect().
      then(() => {
        console.log(`sip wss connected`)
        // this.store.dispatch(appActions.wssSipConnected());
        this.register()
      }).
      catch((e) => { 
//TODO HACK!! rimuovere riga sotto
//this.store.dispatch(appActions.wssSipRegistered({ is: true })); return;

        FELoggerService.error(`sip wss connecting`, LogSource.SIPEvent, e); 
        const msg =`${I18Service.get('Error.Signalr')}\n${I18Service.get('SA_UNAVAILABLEGLBODY')}`
        this.store.dispatch(appActions.showErrorPage({ message: msg })) })
  }

  private tryRegister = 1;
  private retryTimer: any;
  private register() {
    if (!this.ua) return;
    if(this.retryTimer) {
      window.clearTimeout(this.retryTimer);
      this.retryTimer = undefined;
    }
    console.log(`sipService register`)
    const selfThis = this;
    const regOptions: RegistererRegisterOptions = {
      requestDelegate: {
        onReject(response) {
            FELoggerService.error(`register rejected isReceiver:${selfThis.config.isReceiver}`, LogSource.SIPEvent, response)
            if (response.message.statusCode == 408) {
              if (!selfThis.retryTimer) {
                
                if (selfThis.tryRegister > MAX_ONREJECT_RETRY) {
                  selfThis.store.dispatch(appActions.loadingMessage({message: ''}));
                  const msg =`${I18Service.get('Error.Signalr')}\n${I18Service.get('SA_UNAVAILABLEGLBODY')}` 
                  selfThis.store.dispatch(appActions.showErrorPage({ message: msg }))
                  return;
                }

                selfThis.retryTimer = setTimeout( () => selfThis.register(), 5000 );
                selfThis.store.dispatch(appActions.loadingMessage(
                  {message: I18Service.get('SNACKBAR.RECONNECTIONATTEMPT', {current: selfThis.tryRegister, max: MAX_ONREJECT_RETRY}
                )}));
                selfThis.tryRegister++;
              }
              return;
            }
            if (selfThis.config.isReceiver == false) {
              const msg =`${I18Service.get('Error.Signalr')}\n${I18Service.get('POPUP.SESSIONEXPIREDTITLE')}`
              selfThis.store.dispatch(appActions.showErrorPage({ message: msg, showReload: false })) 
            }
        },
      }
      // requestOptions: {
      //   extraHeaders: this.getDefaultExtraHeaders()
      // }
    }
    this.ua.register(regOptions).
      then(() => {
        // console.log(`sip registered`);

        // this.store.dispatch(appActions.wssSipRegistered({is:true}));
        // this.store.dispatch(phoneActions.phoneChangeStatus({status: PhoneStatusEnum.IDLE}));
      }).
      catch((e) => { FELoggerService.error(`sip registered`, LogSource.SIPEvent, e); })

  }

  private getDefaultExtraHeaders(): string[] {
    return [
      `SM-PartecipantId: ${this.config.partecipantId}`, 
      `SM-ClientType: ${AppConfigService.role}`, 
      `SM-SessionId: ${this.config.sessionId}`
    ];
  }

  private createAor(user: string | undefined): string {
    if (!user) {
      throw new Error(`createAor problem: user is null`);
    }
    const rtrProxy = this.config.rtrProxy;
    return `sip:${user}@${rtrProxy.domain}`;
  }


  private isInCall(): boolean {
    // return !!this.ua?. currentSession;
    return !!this.ua && this.ua.sessions.length > 0
  }

  private logSessionEvent(session: Session, msg: string) {
    const who = `[${session?.remoteIdentity?.displayName}] ${session?.remoteIdentity?.uri?.user}`;
    FELoggerService.log(`${msg} ${who}`, LogSource.PhoneEvents);
  }


  public showCurrentSessions(){
    const who=`${AppConfigService.role} - ${this.config.partecipantId}`;
    const obj = this.ua?.showCurrentSessions(who);
    FELoggerService.log(`show current session for ${who}`, LogSource.Default, {who, list:obj});
    NotificationService.show(`sessioni correnti inviate`)
  }
}