import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ElementTypeEnum, FormatType, NgWhiteboardComponent, NgWhiteboardService, ToolsEnum, WhiteboardElement, WhiteboardOptions } from "ng-whiteboard";
import { fromEvent, last, Subscription } from "rxjs";
import { ThumbVideoComponent } from "./thumb-video.component";
import { FileSenderHelper } from "src/app/helpers/fileSender.helper";
import { AppConfigService } from "src/app/services/app.config.service";
import { ResizedEvent } from "src/app/helpers/resized.event";
import { ImageAnalyzer } from "src/app/services/imageAnalyzer.service";
import { NotificationService } from "src/app/services/notification.service";

export type CanvasData = {
    image: string;
    width: number;
    height: number;
}

@Component({
    selector: 'app-whiteboard-video',
    templateUrl: './whiteboard-video.component.html',
    styleUrls: ['./whiteboard-video.component.scss'],
    providers: [NgWhiteboardService],
  })

export class WhiteboardVideoComponent implements OnInit, OnDestroy, AfterViewInit {
    tools = [
        {value: ToolsEnum.SELECT, icon:'arrow_selector_tool'},
        {value: ToolsEnum.BRUSH, icon:'draw'},
        {value: ToolsEnum.LINE, icon:'diagonal_line'},
        {value: ToolsEnum.ARROW, icon:'north_west'},
        {value: ToolsEnum.TEXT, icon:'text_fields'},
        {value: ToolsEnum.RECT, icon:'rectangle'},
        {value: ToolsEnum.ELLIPSE, icon:'circle'},
        {value: ToolsEnum.ERASER, icon:'ink_eraser'}
    ];

    toolSelected = this.tools[0];

    drawingEnabled = false;
    toolsEnum = ToolsEnum;
    //selectedTool  = ToolsEnum.SELECT;
    strokeColor = '#ef5350';
    fillColor = '#ff000000';
    canvasContainerWidth = 0;
    canvasContainerHeight = 0;
    compositeZoom = 1;
    videoCaptureElement: WhiteboardElement | undefined = undefined;
    options = {canvasHeight:0 , canvasWidth:0 };
    private _subscriptionList: Subscription[] = [];
    // @ViewChild('whiteboardContainer') public  whiteboardContainer!: ElementRef;
    @ViewChild(NgWhiteboardComponent, {static: false}) whiteboardComponent! : NgWhiteboardComponent
    whiteboardImage: CanvasData | null = null;
    imageSizeRatio = {width: 640, height: 480, ratio: 1};
    
    @Input('canvasData') set canvasData( value: CanvasData | null){
        this.whiteboardImage = value;

        setTimeout(()=>{
            if (value) {
                // console.log(value.image);
              this.whiteboardService.addImage(value.image);
            }
          }) 
    };
    get canvasData(): CanvasData | null {
        return this.whiteboardImage;
    }

    @Output() close = new EventEmitter<boolean>();
    

    @ViewChild('analyze',{static: false}) analazydiv!: ElementRef;

    constructor(
        public config: AppConfigService,
        private whiteboardService: NgWhiteboardService,
        public imageAnalizer: ImageAnalyzer) {
      }

    ngAfterViewInit(): void {
    }
    ngOnDestroy(): void {
        this._subscriptionList.forEach((subscription) => subscription?.unsubscribe());
    }
    ngOnInit(): void {        
    }
    
    sendVideoCaptureAndCloseWhiteboard(){
        this.whiteboardService.save(FormatType.Base64);
    }
    
    onSave(screenCapture: string) {
        const now = new Date();    
        const formattedDateTime = now.toISOString().replace(":",".");
        const fileName = `videoCapture_${formattedDateTime}.png`;

        FileSenderHelper.sendVideoCapture(screenCapture, fileName, this.config.partecipantId).then( (b:boolean) => {
            this.closeWhiteboard();      
        });
    }
    
    onSelectElement(el: WhiteboardElement | null){
        if(el != null && el.type== ElementTypeEnum.IMAGE){
            this.whiteboardComponent.clearSelectedElement();
        }
    }

    onDeleteElement(el: WhiteboardElement | null){ // evitare che con gomma si cancelli l'immagine di sfondo
        if(el != null && el.type== ElementTypeEnum.IMAGE && this.videoCaptureElement?.id == el.id){
            this.whiteboardService.undo();
        }
    }

    closeWhiteboard(){
        this.close.emit(true);
    }
    
    onResized(event: ResizedEvent) {
        // console.log('********************* resized event', event)
        this.resizeWhiteboard(event.newRect.width, event.newRect.height)
    }

    onDataChange(event: WhiteboardElement[]){ // evitare che una sequenza di undo elimini l'immagine di sfondo
        // console.log('onDataChange - ', event);
        //attualmente prende l'unica immagine presente nel canvass, 
        //in futuro se si vorrano avere più di un'immagine bisognerà modificare il find

        if(event.length == 0 && this.videoCaptureElement){
            this.whiteboardService.redo();
        }
        else{
            this.videoCaptureElement = event.find(z => z.type == ElementTypeEnum.IMAGE);
        }

        const lastElement = event.slice(-1)[0]; //ultimo elemento in lista
        if (lastElement && lastElement.type == ElementTypeEnum.TEXT) {
            lastElement.options.fill = this.strokeColor;
        }

    }

    undo() {
        this.whiteboardService.undo();
    }
    redo() {
        this.whiteboardService.redo();
    }

    public analyzeImage(event: any){
        if(this.whiteboardImage){
            var a = this.imageAnalizer.analyze(this.whiteboardImage.image).subscribe({
                next: async (resp)=> {
                    console.log(resp);

                    const ratio = this.imageSizeRatio.ratio;
                    if(resp.objects && resp.objects.values.length){
                        for(const o of resp.objects.values){
                            
                            const start = {offsetX: o.boundingBox.x * ratio, offsetY: o.boundingBox.y *ratio}
                            const end = {offsetX: (o.boundingBox.x + o.boundingBox.width)*ratio, offsetY: (o.boundingBox.y + o.boundingBox.height)*ratio }
                            const text = `${o.tags[0].name} (${o.tags[0].confidence.toFixed(3)})`;
                            await this.drawRectangle(start, end);
                            await this.drawText(start, text);
                        }
                    }
                    // if(resp.people && resp.people.values.length){
                    //     for(const p of resp.people.values){
                    //         const start = {offsetX: p.boundingBox.x, offsetY: p.boundingBox.y }
                    //         const end = {offsetX: p.boundingBox.x + p.boundingBox.width, offsetY: p.boundingBox.y + p.boundingBox.height }
                    //         const text = `People: (${p.confidence.toFixed(3)})`;
                    //         await this.drawRectangle(start, end);
                    //         await this.drawText(start, text);
                    //     }
                    // }
                    if(resp.caption) {
                        const caption = resp.caption;
                        const text = `description: ${caption.text} (${caption.confidence.toFixed(3)})`
                        await this.drawText({offsetX:0, offsetY:0}, text)
                    }

                    if (event.ctrlKey) {
                        this.analazydiv.nativeElement.innerHTML = JSON.stringify(resp, null, 2);
                        NotificationService.showElement(this.analazydiv.nativeElement, '')
                    }
                },
                error: (err) => {
                    NotificationService.popupError({msg: `analyze api error: ${err.message}`});
                }
            });
            //do the thinghy with the result
        }
    }

    private resizeWhiteboard(width: number, height: number){    
        if(this.whiteboardImage){
            this.options.canvasWidth = this.whiteboardImage.width;
            this.options.canvasHeight = this.whiteboardImage.height; 
            
            //full image
            this.imageSizeRatio = {width: this.whiteboardImage.width, height: this.whiteboardImage.height, ratio: 1}

            if(width < this.whiteboardImage.width || height < this.whiteboardImage.height)
            {
                this.imageSizeRatio = this.resizeRectangleMaintainingAspectRatio(this.whiteboardImage.width, this.whiteboardImage.height, 
                    width, height);
            }

            this.canvasContainerWidth = this.imageSizeRatio.width;
            this.canvasContainerHeight = this.imageSizeRatio.height;
            this.compositeZoom = this.imageSizeRatio.ratio;  

            console.log(`resizeWhiteboard: ${JSON.stringify(this.imageSizeRatio)}`);
        }
    }
    
    private resizeRectangleMaintainingAspectRatio(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) {
        var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        return { width: srcWidth*ratio, height: srcHeight*ratio, ratio };
    }

    private async drawRectangle(start: any, end: any): Promise<void> {
        return Promise.resolve()
            .then(()=>{
                this.whiteboardComponent.handleStartRect(start);
                this.whiteboardComponent.handleDragRect(end);
                this.whiteboardComponent.handleEndRect();
            })
    }
    private drawText(start: any, text: string): Promise<void>  {
        //per il text è necessario impostare il corretto tool e rendere asincrone le chiamate ai vari metodi
        const t = this.whiteboardComponent.selectedTool;
        this.whiteboardComponent.selectedTool = ToolsEnum.TEXT;
        return Promise.resolve()
            .then(() => new Promise(resolve => setTimeout(() => {
                this.whiteboardComponent.handleTextTool(start)
                resolve(null)
            }, 0)))
            .then(() => new Promise(resolve => setTimeout(() => {
                this.whiteboardComponent.updateTextItem(text);
                resolve(null)
            }, 0)))
            .then(() => new Promise(resolve => setTimeout(() => {
                this.whiteboardComponent.tempElement.options.fill = this.strokeColor;
                this.whiteboardComponent.tempElement.options.fontSize = 14 / this.imageSizeRatio.ratio;
                this.whiteboardComponent.finishTextInput();
                
                this.whiteboardComponent.selectedTool = t;

                resolve();
            }, 0)))
    }
}