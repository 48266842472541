import { Injectable } from "@angular/core";
import { CreateEvent } from "../models/WebServer/createEvent";
import { EventTypeEnum } from "../models/WebServer/eventTypeEnum";
import { MediaTypesEnum } from "../models/WebServer/mediaTypesEnum";
import { EventData } from "../models/WebServer/eventData";
import { Phone } from "../models/WebServer/phone";
import { Media } from "../models/WebServer/media";
import { NotificationService } from "../services/notification.service";
import { FELoggerService, LogSource } from "../services/feLogger.service";
import { I18Service } from "../services/i18.service";
import * as appActions from "src/app//store/actions/app.actions";
import { Store } from "@ngrx/store";
import { EventService } from "../services/event.service";
import { RootState } from "../store/root.state";
import { AppConfigService } from "../services/app.config.service";
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class FileSenderHelper {
  
  private static s_config: AppConfigService;
  private static s_store: Store<RootState>;
  private static s_eventService: EventService;
  constructor(config: AppConfigService, store: Store<RootState>, eventService: EventService) {
    console.debug(`***************** FileSenderHelper constructor`)
    FileSenderHelper.s_config = config;
    FileSenderHelper.s_store = store;
    FileSenderHelper.s_eventService = eventService;
  }

  static async attachDocument(e: any, partecipantId: string): Promise<boolean> {
    const files = e.target?.files || [];

    if (files.length > 0) {
      const file=files[0];
      // var type = files[i].type.match(/^image\//) ? MediaTypesEnum.Image : MediaTypesEnum.Document;
      var type = MediaTypesEnum.Document;
      const result = await FileSenderHelper.toBase64(file).catch(e => Error(e));
      if (result instanceof Error) {
        FELoggerService.error(`Errore durante attach documento , ${result.message}`);
      }
      else {
        const createEvent = FileSenderHelper.createDataEvent(result, file.name, partecipantId, '', '', undefined, type);
        // this.sendingAttachment = true;

        return FileSenderHelper.sendMediaEvent(createEvent, 'POPUP.DOCUMENTSENTBODY', 'POPUP.DOCUMENTERRORBODY');
      }
    }
    return new Promise<boolean>( r => { r(false)});
  }

  static async sendVideoCapture(screenCapture: string, fileName: string, partecipantId: string,): Promise<boolean> {
    var type = MediaTypesEnum.Document;
    const createEvent = FileSenderHelper.createDataEvent(screenCapture, fileName, partecipantId, '', '', undefined, type);
    return FileSenderHelper.sendMediaEvent(createEvent, 'POPUP.IMAGESENTBODY', 'POPUP.CORRUPTEDIMGBODY');
  }

  static async sendScreenshotAttachment(url: string, fileName: string, partecipantId: string): Promise<boolean> {
    const createEvent = FileSenderHelper.createDataEvent(url, fileName, partecipantId, '', '');
    return FileSenderHelper.sendMediaEvent(createEvent, 'POPUP.IMAGESENTTITLE', 'POPUP.DOCUMENTERRORBODY');
  }
  static async sendGalleryImageAttachment(url: string, fileName: string, partecipantId: string): Promise<boolean> {
    //const createEvent = FileSenderHelper.createDataEvent(url, fileName, partecipantId, '', '');
    const createEvent = FileSenderHelper.createDataEvent(url, fileName, partecipantId , '', '');
    return FileSenderHelper.sendMediaEvent(createEvent, 'POPUP.IMAGESENTBODY', 'POPUP.CORRUPTEDIMGBODY');
  }
  static async sendCameraImage(captureUrl: string, partecipantId: string): Promise<boolean> {
    var createEvent = FileSenderHelper.createDataEvent(captureUrl , '', partecipantId, '', '');
    return FileSenderHelper.sendMediaEvent(createEvent,'POPUP.IMAGESENTBODY','POPUP.IMAGEERRORBODY');
  }

  private static sendMediaEvent(createEvent: CreateEvent, okMessage: string, koMessage: string): Promise<boolean> {
    console.log(`FileSenderHelper sending event media ${createEvent.data.media?.mediaType}`);
    FileSenderHelper.s_store.dispatch(appActions.loadingMessage({ message: I18Service.get("Loading.now") }));

    return new Promise<boolean >( resolve => {
      
      const notifyError = function(error: any) {
        FileSenderHelper.s_store.dispatch(appActions.loadingMessage({ message: '' }));
        NotificationService.showError(`${I18Service.get(koMessage)}`);
        error && FELoggerService.error(`Error during file Sharing: ${error}`, LogSource.MediaAudio, error);
      }
      
      FileSenderHelper.s_eventService.saveEvent(createEvent).subscribe({
        next: (id: number) => {

          FileSenderHelper.s_eventService.getEvent(id).subscribe({
            next: (ev: CreateEvent) => {
              if (!ev.data.media || !ev.data.media.mediaUrl){ 
                FileSenderHelper.s_store.dispatch(appActions.loadingMessage({ message: '' }));
                resolve(false); return;
              }
              const mediaLink = ev.data.media.mediaUrl;
              FileSenderHelper.s_store.dispatch(appActions.sendMediaMessage({ 
                content: mediaLink, 
                fileName: ev.data?.media?.mediaName || '', 
                mediaType: ev.data.media.mediaType, 
                id: ev.rtpCallId || 'none',
                toIds: appActions.SEND_TO_ALL }))
              FileSenderHelper.s_store.dispatch(appActions.loadingMessage({ message: '' }));
              NotificationService.showSuccess(I18Service.get(okMessage));
              resolve(true);
            },
            error: (e: any) => { notifyError(e);  resolve(false); }

          });
        },
        error: (e: any) => { notifyError(e); resolve(false); }
      });
    });
  }

  private static toBase64(file: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  private static createDataEvent(
    url: string,
    name: string,
    partecipantId: string,
    batteryRaw: string,
    phoneModel: string,
    mimeType: string = ".jpg",
    type: MediaTypesEnum = MediaTypesEnum.Image
  ): CreateEvent {
    const urlParts = url.split(';');
    let base64Image: string;
    let newName: string;
    if (urlParts.length <= 1) { throw new Error(`createDataEvent: wrong urlParts`);}

    base64Image = urlParts[1].replace('base64,', '');
    mimeType = type == MediaTypesEnum.Image ? mimeType : '';
    if (!name) {
      switch (type) {
        case MediaTypesEnum.Image:
          name = `${Date.now()}_capture.jpeg`;
          newName = this.changeExtension(name, 'jpeg');
          break;
        case MediaTypesEnum.Document:
          name = `${Date.now()}_document`;
          break;
        default:
          name = `${Date.now()}_file`;
          break;
      }
    }
    const media: Media = { mediaType: type, mimeType, mediaContent: base64Image, mediaName: name };
    const phone: Phone = { batteryLife: batteryRaw, model: phoneModel };
    const eventData: EventData = { partecipantId, media, phone }
    const createEvent: CreateEvent = { eventType: EventTypeEnum.Media, data: eventData, rtpCallId:  uuidv4()}
    return createEvent;
  }

  private static changeExtension(fileName: string, newExtension: string): string {
    const nameParts = fileName.split('.');
    if (nameParts.length > 1) {
      const ext = nameParts[nameParts.length - 1];
      return fileName.replace(ext, newExtension);
    } else {
      return fileName;
    }
  }

}
