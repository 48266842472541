<div class="container" 
        [ngClass]=" zoom ? 'hide-thumb': '' "
        [hidden]="!onCall && !(enableMyVideo )">
    <!-- <div class="partecipant-name" *ngIf="zoom">{{name}}</div> -->
    <div [id]="'thumbVideo-' + id" class="thumb-container" [ngClass]="{'absoluteVideoBig':zoom}"
        
        (mouseenter)="mouseEnter = true"
        (mouseleave)="mouseEnter = false">
        <div class="thumb-view-container">
            <div class="partecipant-name">
                <span *ngIf="audioDisabled" class="mic-off font-icon-outlined material-symbols-outlined">mic_off</span>
                <span class="label" [ngClass]="{'without_tree_dots': config.isMobilePhone || !toolbar?.children?.nativeElement.children?.length}">
                    {{name}}
                </span>
                <!-- <app-thumb-video-toolbar class="ref-video-toolbar"
                    [zoom]="zoom" [camsCount]="camsCount" [video]="video" 
                    [fullscreen]="fullscreen" [partecipantStatus]="partecipantStatus!"
                    (imageToEdit)="imageToEdit.emit($event)">
                </app-thumb-video-toolbar> -->
                
                <!-- <span *ngIf="!zoom && config.isMobilePhone" class="open-toolbar font-icon-outlined material-symbols-outlined" (click)="openToolbar()">more_vert</span> -->
                <span *ngIf="!config.isMobilePhone && !!toolbar?.children?.nativeElement.children?.length" class="open-toolbar font-icon-outlined material-symbols-outlined" [matMenuTriggerFor]="toolbarMenu">more_vert</span>
                <mat-menu #toolbarMenu="matMenu" >
                    <app-thumb-video-toolbar style="padding-right: 5px;display: block;" #toolbar
                        [zoom]="zoom" [camsCount]="camsCount" [video]="video" 
                        [fullscreen]="fullscreen" [partecipantStatus]="partecipantStatus!"
                        (imageToEdit)="imageToEdit.emit($event)"
                        (imageToWhiteboard)="imageToWhiteboard.emit($event)">
                    </app-thumb-video-toolbar>
                </mat-menu>
            </div>
            <div class="video">
                <span *ngIf="!partecipantStatus?.videoEnabled" (click)="openToolbar()"
                    class="no-video font-icon-outlined material-symbols-outlined">person</span>

                <div *ngIf="partecipantStatus?.videoEnabled && videoFreeze" class="video-freeze">{{'Video.freeze' | translate}}</div>
                <video  #video playsinline autoplay oncontextmenu="return false;"
                    preload="auto"
                    [ngxFullscreen]="video"
                    #fullscreen="ngxFullscreen"
                    (click)="onVideoClick()"
                    (dblclick)="onVideoDoubleClick()"
                    class="video"
                    [hidden]="!partecipantStatus?.videoEnabled"
                    [ngStyle]="{'object-fit': toolbar.videoCover ? 'cover' : 'contain'}"
                    [ngClass]="(onCall && false) ? 'hide-loader': ''"
                ></video>
                <!-- <div class="overlay" *ngIf="zoom">
                    <app-thumb-video-toolbar 
                        [zoom]="zoom" [camsCount]="camsCount" [video]="video" 
                        [fullscreen]="fullscreen" [partecipantStatus]="partecipantStatus!"
                        (imageToEdit)="imageToEdit.emit($event)"
                        >

                    </app-thumb-video-toolbar>
                </div> -->
            </div>
            <div class="video" [hidden]="partecipantStatus?.videoEnabled"></div>
        </div>
    </div>
</div>